$(function() {
  //caches a jQuery object containing the header element
  var header = $('.primary-navigation');
  var headerHeight = header.height();
  var htmlBody = $('html, body');

  $(window).scroll(function() {
    var scroll = $(window).scrollTop();

    if (scroll >= 50) {
      header.addClass('primary-navigation--dark');
    } else {
      header.removeClass('primary-navigation--dark');
    }
  });

  $('.button--jump_link').click(function(e) {
    htmlBody.animate({
      scrollTop:
        $(this)
          .closest('.section')
          .next('.section')
          .position().top - headerHeight
    });
  });

  $('a[href^="#"]').click(function(e) {
    htmlBody.animate({
      scrollTop: $($.attr(this, 'href')).position().top - headerHeight
    });

    return false;
  });
});
